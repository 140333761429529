// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zvWd56AvX", "WkcoDXhEF", "GAS3evhiS", "nbHtXTc9X"];

const serializationHash = "framer-GRlIc"

const variantClassNames = {GAS3evhiS: "framer-v-1n6ffex", nbHtXTc9X: "framer-v-1iw7rzh", WkcoDXhEF: "framer-v-1sn1vg0", zvWd56AvX: "framer-v-uao6y1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"L / Active": "WkcoDXhEF", "L / Inactive": "zvWd56AvX", "S / Active": "nbHtXTc9X", "S / Inactive": "GAS3evhiS"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, c5LJ3MZkW: tap ?? props.c5LJ3MZkW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zvWd56AvX"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, c5LJ3MZkW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zvWd56AvX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap11mr9xy = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (c5LJ3MZkW) {
const res = await c5LJ3MZkW(...args);
if (res === false) return false;
}
setVariant("WkcoDXhEF")
})

const onTap1ugeh8e = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (c5LJ3MZkW) {
const res = await c5LJ3MZkW(...args);
if (res === false) return false;
}
setVariant("zvWd56AvX")
})

const onTapjaxtt8 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (c5LJ3MZkW) {
const res = await c5LJ3MZkW(...args);
if (res === false) return false;
}
setVariant("nbHtXTc9X")
})

const onTap9mc0nm = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (c5LJ3MZkW) {
const res = await c5LJ3MZkW(...args);
if (res === false) return false;
}
setVariant("GAS3evhiS")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uao6y1", className, classNames)} data-framer-name={"L / Inactive"} data-highlight layoutDependency={layoutDependency} layoutId={"zvWd56AvX"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap11mr9xy} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-59a5b926-bc8b-446d-ae39-ed15103ee164, rgb(229, 229, 229))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, boxShadow: "inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1)", ...style}} variants={{nbHtXTc9X: {backgroundColor: "var(--token-f5bbcd0c-3b82-4dbd-94cb-c69bafd7aca8, rgb(10, 10, 10))"}, WkcoDXhEF: {backgroundColor: "var(--token-f5bbcd0c-3b82-4dbd-94cb-c69bafd7aca8, rgb(10, 10, 10))"}}} {...addPropertyOverrides({GAS3evhiS: {"data-framer-name": "S / Inactive", onTap: onTapjaxtt8}, nbHtXTc9X: {"data-framer-name": "S / Active", onTap: onTap9mc0nm}, WkcoDXhEF: {"data-framer-name": "L / Active", onTap: onTap1ugeh8e}}, baseVariant, gestureVariant)}><motion.div className={"framer-16ve7ly"} data-framer-name={"Handle"} layoutDependency={layoutDependency} layoutId={"O8L8iNh8F"} style={{backgroundColor: "var(--token-80553515-7ab0-44ac-b981-96d7680674fd, rgb(255, 255, 255))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, boxShadow: "1px 1px 2px 0px rgba(0, 0, 0, 0.15), inset 0px -2px 2px 0px rgba(0, 0, 0, 0.1)"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GRlIc.framer-dtjzmt, .framer-GRlIc .framer-dtjzmt { display: block; }", ".framer-GRlIc.framer-uao6y1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: auto; justify-content: flex-start; overflow: visible; padding: 2px 2px 2px 2px; position: relative; width: 44px; }", ".framer-GRlIc .framer-16ve7ly { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); overflow: hidden; position: relative; width: 20px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GRlIc.framer-uao6y1 { gap: 0px; } .framer-GRlIc.framer-uao6y1 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-GRlIc.framer-uao6y1 > :first-child { margin-left: 0px; } .framer-GRlIc.framer-uao6y1 > :last-child { margin-right: 0px; } }", ".framer-GRlIc.framer-v-1sn1vg0.framer-uao6y1 { justify-content: flex-end; }", ".framer-GRlIc.framer-v-1n6ffex.framer-uao6y1 { width: 36px; }", ".framer-GRlIc.framer-v-1n6ffex .framer-16ve7ly, .framer-GRlIc.framer-v-1iw7rzh .framer-16ve7ly { height: var(--framer-aspect-ratio-supported, 16px); width: 16px; }", ".framer-GRlIc.framer-v-1iw7rzh.framer-uao6y1 { justify-content: flex-end; width: 36px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"WkcoDXhEF":{"layout":["fixed","auto"]},"GAS3evhiS":{"layout":["fixed","auto"]},"nbHtXTc9X":{"layout":["fixed","auto"]}}}
 * @framerVariables {"c5LJ3MZkW":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZtbm9O8Tk: React.ComponentType<Props> = withCSS(Component, css, "framer-GRlIc") as typeof Component;
export default FramerZtbm9O8Tk;

FramerZtbm9O8Tk.displayName = "Switch";

FramerZtbm9O8Tk.defaultProps = {height: 24, width: 44};

addPropertyControls(FramerZtbm9O8Tk, {variant: {options: ["zvWd56AvX", "WkcoDXhEF", "GAS3evhiS", "nbHtXTc9X"], optionTitles: ["L / Inactive", "L / Active", "S / Inactive", "S / Active"], title: "Variant", type: ControlType.Enum}, c5LJ3MZkW: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerZtbm9O8Tk, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})